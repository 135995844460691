<template>
  <div v-if="feedbacks.length > 0">
    <b-table
      hover
      class="m-0"
      :items="feedbacks"
      :fields="tablesFields[type]"
      stacked="md"
    >
      <template #cell(#)="data">
        {{ data.index + 1 }}
      </template>
      <template #cell(last_date)="data">
        <div v-html="getDataStatus(data.item.last_date, isRegular)"></div>
      </template>
      <template #cell(total)="data">
        <span>{{ data.item.total > 0 ? data.item.total : '-' }}</span>
      </template>
    </b-table>
  </div>
  <div v-else>
    <b-table hover :fields="tablesFields[type]" />
    <EmptyPage/>
  </div>
</template>

<script>
import EmptyPage from '@/components/empty-page';

export default {
  name: 'UserFeedbacksTable',
  components: {
    EmptyPage
  },
  props: {
    feedbacks: {
      type: Array,
      required: true
    },
    type: {
      type: String,
      required: true,
      validator(value) {
        return ['sent', 'received'].includes(value)
      }
    },
    isRegular: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      tablesFields: {
        sent: [
          {
            key: '#',
            label: '#'
          },
          {
            key: 'recipient.name',
            label: 'Employee'
          },
          {
            key: 'last_date',
            label: 'Last Feedback'
          },
          {
            key: 'total',
            label: '# reviews'
          },
        ],
        received: [
          {
            key: '#',
            label: '#'
          },
          {
            key: 'author.name',
            label: 'Employee'
          },
          {
            key: 'last_date',
            label: 'Last Feedback'
          },
          {
            key: 'total',
            label: '# reviews'
          }
        ]
      },
    }
  },
  methods: {
    getDataStatus(date, isRegular) {
      const mDate = this.$moment(date).utc(true)
      if (mDate.isValid()) {
        const today = this.$moment()
        const diff = today.diff(mDate, 'days')
        if (diff >= 7 && isRegular) {
          return `${mDate.fromNow()} <span class="text-danger">(!)</span>`
        } else {
          return `${mDate.fromNow()}`
        }
      } else {
        return 'Never <span class="text-danger">(!)</span>'
      }
    }
  }
}
</script>
