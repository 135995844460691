<template>
  <layout v-load="loading">
    <h3
      v-if="employee?.name"
      class="mb-3"
    >
      {{ employee.name }} board
    </h3>
    <hr>
    <div class="row">
      <div class="col-md-6">
        <h2 class="pb-3">Sent</h2>
        <div class="d-flex flex-column">
          <h5>Regular colleagues</h5>
          <b-card body-class="p-0">
            <feedback-table
                :feedbacks="sentFeedbacks.filter(item => item.is_regular_colleage)"
                :type="'sent'"
            />
          </b-card>
          <h5>Other colleagues</h5>
          <b-card body-class="p-0">
            <feedback-table
                :feedbacks="sentFeedbacks.filter(item => !item.is_regular_colleage)"
                :type="'sent'"
                :isRegular="false"
            />
          </b-card>
        </div>
      </div>
      <div class="col-md-6">
        <h2 class="pb-3">Received</h2>
        <div class="d-flex flex-column">
          <h5>Regular colleagues</h5>
          <b-card body-class="p-0">
            <feedback-table
                :feedbacks="receivedFeedbacks.filter(item => item.is_regular_colleage)"
                :type="'received'"
            />
          </b-card>
          <h5>Other colleagues</h5>
          <b-card body-class="p-0">
            <feedback-table
                :feedbacks="receivedFeedbacks.filter(item => !item.is_regular_colleage)"
                :isRegular="false"
                :type="'received'"
            />
          </b-card>
        </div>
      </div>
    </div>
  </layout>
</template>

<script>
import FeedbackTable from '@/components/user-feedbacks-table.vue';

export default {
  name: 'UserDashboard',
  components: {
    FeedbackTable
  },
  data() {
    return {
      userId: null,
      loading: false
    }
  },
  async mounted() {
    const { id } = this.$route.params;
    if (id) {
      this.userId = id;
      this.loading = true;
      if (!this.employees.length) {
        await this.$store.dispatch('weeklyFeedback/fetchAllEmployees');
      }
      await this.$store.dispatch('weeklyFeedback/fetchSentFeedbackById', id);
      await this.$store.dispatch('weeklyFeedback/fetchReceivedFeedbackById', id);
      this.loading = false;
    }
  },
  beforeDestroy() {
    this.$store.commit('weeklyFeedback/SET_SENT_FEEDBACKS', []);
    this.$store.commit('weeklyFeedback/SET_RECEIVED_FEEDBACKS', []);
  },
  computed: {
    sentFeedbacks () {
      return this.$store.state.weeklyFeedback.sentFeedbacks
    },
    receivedFeedbacks () {
      return this.$store.state.weeklyFeedback.receivedFeedbacks
    },
    employees() {
      return this.$store.state.weeklyFeedback.employees;
    },
    employee() {
      return this.employees.find(({ uid }) => uid === this.userId);
    }
  }
}
</script>
